import styled from 'styled-components';

// 我现在只在默认的情况看到这个使用
export const PriceItemStyle = styled.div`
  display: flex;
  align-items: center;
  .cur-price {
    display: flex;
    align-items: center;
  }
  .max-price {
    display: flex;
    align-items: center;
    .line-item {
      margin: 0 4px;
      color: var(--color_sale_text);
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
    }
  }
  /* 这个是折扣价 颜色写死 字体跟划线价格  */
  .discount-text {
    display: flex;
    margin-left: 24px;
    align-items: center;
    color: #982424;
    /* discount-text中有一个描述 所以本身也设置字体大小 保持和商品原价一样 */
    .desc-text {
      ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size ? `h${props.size}` : 'h10'])};
    }
    /* 颜色现在定死 */
    .price-sale,
    .price-sale-origin {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: #982424;
      }
    }
  }
`;

/**
 * HtGoodsDetailPrice
 */
export const GoodsDetailPriceStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.getSalePriceFontSize(props.theme[props.size])};
  .cur-price {
    display: flex;
    align-items: center;
  }
  .max-price {
    display: flex;
    align-items: center;
  }
  .line-item {
    margin: 0 4px;
  }
  /** 优先展示会员价 */
  &.member-price-first {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    // 会员价
    .member-price-box {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: var(--color_sale_text) !important;
      }
    }
    // 售价
    .cur-price {
      .price-symbol,
      .price-integer,
      .price-decimal,
      .line-item {
        color: var(--color_body_text) !important;
      }
    }

    &.big {
      // 会员价
      .member-price-box {
        .price-item,
        .price-symbol,
        .price-integer,
        .price-decimal {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
        }
      }
      // 售价
      .cur-price {
        .price-item,
        .price-symbol,
        .price-integer,
        .price-decimal,
        .line-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
        }
      }
    }
    &.small {
      .member-price-box {
        .price-item,
        .price-symbol,
        .price-integer,
        .price-decimal {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
        }
      }
      .cur-price {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item,
        .line-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        }
      }
    }
  }
  &.origin-price-first {
    align-items: center;
    .line-item {
      color: var(--color_sale_text);
    }
    &.big {
      // 会员价
      .member-price-box {
        margin-left: 8px;
        /* .member-price-wrapper, */
        .price-item,
        .price-symbol,
        .price-integer,
        .price-decimal {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
        }
        .member-price {
          margin-top: 0px !important;
        }
      }
      // 售价
      .cur-price {
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item,
        .line-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
        }
      }
    }
    &.small {
      .member-price-box {
        margin-left: 6px;
        /* .member-price-wrapper, */
        .price-item,
        .price-symbol,
        .price-integer,
        .price-decimal {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        }
        .member-price {
          margin-top: 0px !important;
        }
      }
      .cur-price {
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item,
        .line-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
        }
      }
    }
  }
`;

/**
 * HtGoodsSkuPrice 会员价展示
 */
export const PriceSkuMemberStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .member-price-wrapper,
  .member-price {
    margin-top: 0px !important;
  }
  .member-price.default,
  .cur-price-not-first {
    color: var(--color_body_text);
  }
  .member-price.is-member {
    color: var(--color_sale_text);
  }
  &.small {
    .member-price-wrapper {
      /**默认大小 */
      .member-price.default {
        margin-left: 6px;
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        }
      }
      /**是会员状态下的大小 */
      .member-price.is-member {
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
        }
      }
    }
    .origin-price-first {
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
      }
    }
    .cur-price-not-first {
      margin-left: 6px;
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
      }
    }
  }
  &.big {
    .origin-price-first {
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
      }
    }
    .cur-price-not-first {
      margin-left: 8px;
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
      }
    }
    .member-price-wrapper {
      /**是会员状态下的大小 */
      .member-price.is-member {
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item,
        .price-decimal {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
        }
      }
      .member-price.default {
        margin-left: 8px;
        .price-symbol,
        .price-integer,
        .price-decimal,
        .price-item,
        .line-item,
        .member-price {
          ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
        }
      }
    }
  }
`;

/**
 *  HtGoodsSkuPrice 折扣活动展示
 */
export const PriceSkuStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  // 划线价标签时使用
  &.show-dash-discount-label-true {
    color: var(--color_activity_label);
    .cur-price {
      .desc-text,
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        color: var(--color_activity_label) !important;
      }
    }
  }
  /* 这个是折扣价 颜色写死 字体跟划线价格  */
  .discount-text {
    display: flex;
    align-items: center;
    background-color: rgba(255, 109, 0, 0.1);
    padding: 2px 6px;
    /* border-radius: 4px; */
    border-radius: var(--border_radius_tag);
    font-weight: 400;
    .desc-text,
    .price-symbol,
    .price-integer,
    .price-decimal,
    .price-item {
      color: rgba(255, 109, 0, 1) !important;
    }
  }
  .dash-discount-label {
    margin-left: 4px;
  }

  .underlined-price {
    margin-left: 8px;
    text-decoration: line-through;
    color: ${(props) => props.theme.colorBodyTexts?.['60']};
  }

  &.big {
    .cur-price {
      .price-item,
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h7)};
      }
    }
    .underlined-price {
      margin-left: 8px;
      .price-item,
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
      }
    }
    .discount-text {
      margin-left: 8px;
      .price-item,
      .price-symbol,
      .price-integer,
      .price-decimal,
      .desc-text {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
      }
    }
  }
  &.small {
    .cur-price {
      .price-item,
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
      }
    }
    .underlined-price {
      margin-left: 6px;
      .price-item,
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
      }
    }
    .discount-text {
      margin-left: 6px;
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item,
      .desc-text {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
      }
    }
  }
`;

export const PriceStyle = styled.div`
  /* 最底层的 删掉strong 和 normal了 而是根据商品原价 商品原价（否折扣和划线）来区分不同样式 */
  ${(props) => props.theme.getSalePriceFontSize(props.theme[props.size])};
  .price-symbol {
    margin-right: 2px;
  }
  &.price-sale {
    ${(props) => props.theme.getSalePriceFontSize(props.theme[props.size])};
    .price-symbol,
    .price-integer,
    .price-decimal {
      ${(props) => props.theme.getSalePriceFontSize(props.theme[props.size])};
      color: var(--color_sale_text);
    }
  }
  &.price-sale-origin {
    ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
    .price-symbol,
    .price-integer,
    .price-decimal {
      ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
      color: ${(props) => props.theme.colorBodyTexts?.['60']};
    }
  }
  .is-special {
    .price-decimal {
      font-size: 80% !important;
    }
  }
  /* .price-decimal {
        font-size: 80% !important;
    } */
`;

export const HtGoodsPriceWithDiscountStyle = styled.div`
  display: flex;
  align-items: center;
  &.show-member-first {
    .cur-price,
    .underlined-price {
      .price-sale-origin,
      .price-sale,
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
      }
    }
    .cur-price {
      .price-sale,
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: var(--color_text);
      }
    }
  }
  /* .cur-price {
        .price-sale,
        .price-symbol,
        .price-integer,
        .price-decimal {
            ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
        }
    } */
  .underlined-price {
    text-decoration: line-through;
    color: ${(props) => props.theme.colorBodyTexts?.['60']};
    margin-left: 4px;
    .price-sale-origin,
    .price-symbol,
    .price-integer,
    .price-decimal {
      color: ${(props) => props.theme.colorBodyTexts?.['60']};
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
    }
  }
`;

export const HtGoodsListSkuPriceStyle = styled.div`
  display: flex;
  flex-direction: column;
  &.member-price-first {
    flex-direction: column-reverse;
  }
`;

export const MemberPriceStyle = styled.div`
  .member-price {
    display: flex;
    align-items: center;
    &.is-member {
      display: inline-flex;
      border-radius: 2px;
      .price-symbol,
      .price-integer,
      .price-decimal {
        font-weight: 600 !important;
        ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size ? `h${props.size}` : 'h10'])};
      }
    }
    .member-icon {
      margin-left: 4px;
      display: flex;
      align-items: center;
      border-radius: 2px;
      background: ${(props) => props.gLabelText};
      color: ${(props) => props.gLabelBg};
      padding: 1px 3px;
    }
    &.default {
      display: inline-flex;
      border-radius: 2px;
      margin-top: 4px;
      padding: 1px 4px;
      .price-symbol,
      .price-integer,
      .price-decimal,
      .price-item {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        color: ${(props) => props.gLabelText} !important;
      }
    }
  }
`;

export const HtGoodsPriceWithDiscountStyle2 = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  .cur-price {
    display: inline-flex;
    align-items: center;
    .price-sale,
    .price-symbol,
    .price-integer,
    .price-decimal {
      color: var(--color_text) !important;
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h9)};
    }
  }
  .underlined-price {
    text-decoration: line-through;
    color: ${(props) => props.theme.colorBodyTexts?.['60']};
    .price-sale-origin,
    .price-symbol,
    .price-integer,
    .price-decimal {
      color: ${(props) => props.theme.colorBodyTexts?.['60']};
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h11)};
    }
  }
  .member-icon {
    margin-left: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }
`;
