import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import LocalStorage from 'utils/local-storage';
import { intlMessage } from 'utils/intl/formate';
import { GOODS_BUY_ONCE, GROUP_BUYING } from 'utils/local-storage/config';
import { getSkuInfo, getGoodsSkuUrl } from 'helper/goods';
import { buriedPoint } from 'utils/log-buried-point';
import { pushToHistory } from 'utils/web-tool';
import Toast from 'cpn/toast';
import { useTips } from './notify';
import { HEAD_ICON_TYPE } from 'const';
import * as cartHelper from 'helper/cart';
import { getAdditionalItemsValidType, ValidType } from 'cpn/sku-list/additional-items/utils';
import { Notify } from 'zent';
import { useI18n } from 'hook/intl';

// 拼接附加项的选中名称+数量的字符串
export const getGoodsAdditionalItemsSelectedText = (goodsAdditionalItemsSelected, attachProps, splitSymbol = '; ') => {
  const values = goodsAdditionalItemsSelected || {};
  return attachProps
    .map((item) => {
      const { propId, optionRules = [] } = item;
      const propSelected = values[propId] || {};
      let total = 0;
      const text = optionRules
        .map((option) => {
          const { optionTitle, optionId } = option;
          const quantity = propSelected[optionId]?.quantity || 0;
          total += quantity;
          return {
            quantity,
            optionTitle,
          };
        })
        .filter((option) => option.quantity > 0)
        .map((option) => `${option.optionTitle}×${option.quantity}`)
        .join(splitSymbol);

      return {
        propId,
        total,
        text,
      };
    })
    .filter((attachProp) => attachProp.total > 0)
    .map((attachProp) => attachProp.text)
    .join(splitSymbol);
};

// 选择附加项
export const selectAttachProps = ({ goodsDetail, item, count, cart, setCart }) => {
  const { goodsId, options = [], variants = [] } = goodsDetail;
  const { propId, optionId, price, optionTitle, propRule = {} } = item;
  const preGoodsAdditionalItem = cart?.goodsAdditionalItems?.[propId] || {};
  const preCount = preGoodsAdditionalItem[optionId]?.quantity;
  let curVariantId;
  if (options.length === 0) {
    curVariantId = variants[0].variantId;
  }
  // 之没有添加过
  if (!preCount) {
    const { multi, required } = propRule;
    const isSingle = (multi === 0 && required === 1) || (multi === 0 && required === 0);
    const additionItem = {
      [propId]: {
        ...(isSingle ? {} : preGoodsAdditionalItem),
        [optionId]: {
          // 虽然价格不需要，但是保持对象结构吧，方便以后拓展
          price,
          quantity: 1,
          optionTitle,
        },
      },
    };
    setCart({
      variantId: curVariantId,
      ...cart,
      goodsId,
      goodsAdditionalItems: {
        ...(cart.goodsAdditionalItems || {}),
        ...additionItem,
      },
    });
  } else {
    // 之前添加过，累加数量
    setCart({
      variantId: curVariantId,
      ...cart,
      goodsId,
      goodsAdditionalItems: {
        ...cart.goodsAdditionalItems,
        [propId]: {
          ...preGoodsAdditionalItem,
          [optionId]: {
            price,
            quantity: preCount + count,
            optionTitle,
          },
        },
      },
    });
  }
};

// 判断商品和附加项是否都符合选择要求
export const isGoodsSelectedSkuOrAttachProps = ({ cart, intl, goodsDetail, $fm, showToast = true }) => {
  if (!cart.variantId) {
    if (showToast) {
      Toast.info(intlMessage(intl, 'goods.sku.empty'), 800);
    }
    return false;
  }
  // 对附加项的判断
  const { attachProps = [] } = goodsDetail;
  const propsFirstNotValid = getAdditionalItemsValidType(attachProps, cart, $fm).find(
    (v) => v.valid === ValidType.Error,
  );
  if (propsFirstNotValid) {
    if (propsFirstNotValid.toast && showToast) {
      Notify.warn(propsFirstNotValid.toast);
    }
    return false;
  }
  return true;
};

export const getAttachProps = (cartItem) => {
  const { goodsAdditionalItems = {} } = cartItem;
  const attachProps = Object.keys(goodsAdditionalItems)
    .map((propId) => {
      const item = goodsAdditionalItems[propId] || {};
      const values = Object.keys(item)
        .map((optionId) => {
          const quantity = item[optionId]?.quantity;
          return {
            quantity,
            id: +optionId,
          };
        })
        .filter((value) => !!value.quantity);
      return {
        values,
        id: +propId,
      };
    })
    .filter((prop) => prop.values.length > 0);
  return attachProps;
};

/** 购物相关 */
export const useCart = ({ goodsDetail, history, goodsId }) => {
  const intl = useIntl();
  const defaultCart = {
    goodsId: goodsDetail?.goodsId,
    title: goodsDetail?.title || '',
    imageUrl: '',
    variantId: '', // sku id
    sku: [],
    price: 0,
    quantity: cartHelper.getDefaultQuantity(goodsDetail),
    priceSymbol: '$',
    requiresTax: 0, // 是否需要税收
    goodsAdditionalItems: {},
  };
  const [cart, setCart] = useState(defaultCart);
  const [isInitNeedCheck, setIsInitNeedCheck] = useState(false);
  // const [cartList, setCartList] = useState(defaultCart);
  const { showSuccessTip } = useTips();
  const { $fm } = useI18n(intl);

  // 顾客选购商品的个数
  const onNumChange = (value) => {
    if (Number(value) < 0) return;
    setCart({
      ...cart,
      quantity: Number(value),
    });
  };

  const isHaveGoods = () => {
    setIsInitNeedCheck(true);
    return isGoodsSelectedSkuOrAttachProps({ cart, intl, goodsDetail, $fm });
  };

  /**
   * 立即购买
   *  1.校验参数
   *  2.更新数据
   *  3.数量置为1
   *  4.跳转页面
   *  5.埋点
   */
  const buySingleGoods = (type, orderParams = {}) => {
    if (type !== 'pre_paypal_listen' && !isHaveGoods()) return;
    const attachProps = getAttachProps(cart);
    const newCart = {
      ...cart,
      attachProps,
    };
    if (type === 'pre_paypal' || type === 'pre_paypal_listen') {
      return [newCart];
    }
    LocalStorage.set(GOODS_BUY_ONCE, [newCart], -1);
    onNumChange(1);
    pushToHistory(history, '/order/subscribe', {
      step: 'info',
      refer: GOODS_BUY_ONCE,
      ...orderParams,
    });
    // 判断是分组
    if (window.global_data.collectionId) {
      buriedPoint('category_buynow', {
        source: '',
        ...newCart,
        goods_id: goodsId,
        goodsType: goodsDetail?.goodsType,
      });
    }
    buriedPoint(
      'buy_now',
      {
        source: {
          cartList: [newCart],
        },
        ...newCart,
        goods_id: cart.goodsId,
        goodsType: goodsDetail?.goodsType,
      },
      {
        name: '立即购买',
        eventType: 'click',
      },
    );
  };

  const setCartList = async () => {
    const { giftGoodsFlag, goodsId, variantId, quantity = 1 } = cart;
    const attachProps = getAttachProps(cart);
    await cartHelper.addGoods({
      giftGoodsFlag,
      goodsId,
      quantity,
      variantId,
      attachProps,
    });
    // const newList = await cartHelper.refreshCartGoods();
    // let cartList = LocalStorage.get(SHOP_CART) || [];
    // const index = cartList.findIndex((item) => item.variantId === cart.variantId);
    // if (index !== -1) {
    //     cartList[index].quantity = Number(cartList[index].quantity) + Number(cart?.quantity);
    // } else {
    //     cartList = [cart, ...cartList];
    // }
    // LocalStorage.set(SHOP_CART, cartList, -1);
    // onNumChange(quantity);
    showSuccessTip({
      text: intl.formatMessage({ defaultMessage: '已添加至购物车', id: 'has.add.car' }),
      targetId: HEAD_ICON_TYPE.cart,
    });
    buriedPoint(
      'add_cart',
      {
        source: '',
        goods_id: cart?.goodsId,
        ...cart,
        sku: [cart?.variantId],
        goodsType: goodsDetail?.goodsType,
        quantity,
      },
      {
        name: '添加购物车',
        eventType: 'click',
      },
    );
  };

  /**
   * 添加购物车
   */
  const addCart = () => {
    if (!isHaveGoods()) return;
    setCartList();
  };

  /** 点击拼团购买 */
  const onGroupBuying = (orderParams = {}) => {
    if (!isHaveGoods()) return;
    const attachProps = getAttachProps(cart);
    const newCart = {
      ...cart,
      attachProps,
    };
    LocalStorage.set(GROUP_BUYING, [newCart], -1);
    pushToHistory(history, '/order/subscribe', {
      step: 'info',
      refer: GROUP_BUYING,
      ...orderParams,
    });
  };

  const onSkuSelect = (item, index) => {
    const newSku = cart.sku;
    const totalSkuCount = goodsDetail?.options?.length;
    let selectedSkuList = cart.selectedSkuList || [];
    if (selectedSkuList[index]?.title === item?.title) {
      selectedSkuList[index] = undefined;
    } else {
      selectedSkuList[index] = item;
    }
    selectedSkuList = selectedSkuList.filter((i) => !!i);
    cart.compareAtPrice = null;
    if (newSku[index] === item?.title) {
      newSku[index] = undefined;
    } else {
      newSku[index] = item?.title;
    }
    const selectSku = newSku.filter((i) => !!i);
    const isSelectedComplete = selectSku?.length === totalSkuCount; // 是否选择了完整的规格
    const skuInfo = getSkuInfo({ sku: newSku, goodsDetail });
    // 判断起购数, 设置默认数量
    const curNum = cartHelper.getDefaultQuantity({
      ...goodsDetail,
      ...skuInfo,
    });

    const goodsSkuUrl = getGoodsSkuUrl({ item, cart, selectedSkuList, goodsDetail });

    if (isSelectedComplete) {
      setCart({
        ...skuInfo,
        goodsAdditionalItems: cart.goodsAdditionalItems,
        quantity: curNum,
        sku: newSku,
        selectedSkuList,
        goodsId: goodsDetail?.goodsId,
        goodsSkuUrl,
        isSelectedComplete,
      });
    } else {
      setCart({
        ...cart,
        ...skuInfo,
        quantity: curNum,
        sku: newSku,
        selectedSkuList,
        goodsId: goodsDetail?.goodsId,
        goodsSkuUrl,
        isSelectedComplete,
      });
    }
  };

  // 附加项数量加减 --> 选择
  const onGoodsAdditionalItemSelect = (item, count) => {
    selectAttachProps({ goodsDetail, item, count, cart, setCart });
  };

  useEffect(() => {
    if (!goodsDetail && !goodsId) return;

    const { withVariants } = goodsDetail || {};
    if (!withVariants) {
      setCart({
        ...cart,
        goodsId: goodsDetail?.goodsId,
        ...getSkuInfo({ goodsDetail, sku: [] }),
      });
    }
  }, [goodsDetail]);

  return {
    cart,
    setCart,
    maxCartNum: cart?.variantId ? cart?.inventory : Infinity, // 可选择的商品最大数量，因为很多地方用就返回一下
    onNumChange,
    buySingleGoods,
    addCart,
    onSkuSelect,
    onGoodsAdditionalItemSelect,
    getAttachProps,
    onGroupBuying,
    isInitNeedCheck,
    setIsInitNeedCheck,
  };
};
