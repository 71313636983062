/* eslint-disable lean-imports/import */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import BuyNow from '../../../components/bay-now';
import NewLink from 'cpn/new-link';
import { Style, PriceStyle } from './style';
import { HtGoodsListPrice } from 'cpn/goods-price';
import { TagList } from 'components/page/goods/tag-list';
import { View } from '../../../../components/base';
import GoodsImage from '../goods-image';
import { useThemeContext } from '../../../../hook/global-theme';
import SimpleGoodsBtn from '../../../../components/page/wish-btn';
import GoodsColorSku from '../goods-color-sku';
import GoodsButton from '../goods-button';
import GoodsScore from '../goods-score';
import { buriedPoint } from 'utils/log-buried-point';
import { getReviewCountStr } from 'utils/formate';
import { DashDiscountLabel } from '../../../components/discount/dash-discount-label';

const GoodsPrev = (props) => {
  const {
    className = '',
    settings,
    goodsInfo = {},
    initialData = {},
    // media = [],
    grid = 2,
    showWish = false,
    commentConfig = {},
    shoppingCarConfig = { showShoppingCar: false, style: 'style1' },
    showSale = false,
    showVendor = false,
    showShoppingBuy = false,
    openCard = false,
    cardColor = '#ffffff',
    productTitleAlign = 'left',
    returnGoodsNameNode,
    returnGoodsPriceNode,
    returnBottomInfoNode,
    returnTagListNode,
    goodsNameHeight = 0,
    goodsPriceHeight = 0,
    bottomInfoHeight = 0,
    tagListHeight = 0,
    // 商品组合3.0有个强调商品功能，需要统一按钮排列方式，所以需要单独处理，
    // setButtonDisplayType返回第一个goodsPrev按钮的排列方式，
    // buttonDisplayType统一设置其他goodsPrev
    buttonDisplayType,
    setButtonDisplayType,
    imageFillType = 'cover',
    showProductName = true,
    showProductPrice = true,
    defaultImageIsSquare = true,
    showDashDiscountLabel = false,
  } = props;
  const {
    product_name_size: productNameSize,
    product_price_size: productPriceSize,
    text_line_limit: textLineLimit = 'auto',
    image_ratio: imageRatio,
    hover_show_second_pic: hoverShowSecondPic,
    strong_product: strongProduct,
    text_display: textDisplay = 'under_pic',
    text_position: textPosition = 'left top',
  } = settings || {};
  const {
    goodsId,
    // quantity,
    handle,
    title,
    // minPrice,
    compareAtPrice,
    discountInfo,
    activityDescriptionInfo,
    vendor,
    soldNum,
    goodsReviewsInfo,
    bestVariant = {},
    inventory,
    variants = [],
  } = goodsInfo;

  const { kdtId, shopSetting = {} } = initialData;
  const { currency, language } = shopSetting;
  const [skuBlockStatus, setSkuBlockStatus] = useState(false);
  const [isMultipleSku, setIsMultipleSku] = useState(false);
  // 是否直接立即购买  否则就是显示添加到购物车
  const [enablePaymentButton, setEnablePaymentButton] = useState(false);
  // sku page 组件是否需要
  const [skuPageShow, setSkuPageShow] = useState(false);
  // sku page 组件是否可用
  const [skuPageCompleteStatus, setSkuPageCompleteStatus] = useState(false);
  const [actionWrapperClass, setActionWrapperClass] = useState('action-wrapper');
  const [sellOutTagClass, setSellOutTagClass] = useState('');
  const intl = useIntl();
  const ele = useRef(null);
  const skuSelectRef = useRef({});
  const goodsRef = useRef();
  const themeContext = useThemeContext();
  const { clientWidth, isMobile, isPc } = themeContext;

  const [currentSkuImage, setCurrentSkuImage] = useState('');

  const goodsNameRef = useCallback((node) => {
    if (node !== null) {
      returnGoodsNameNode && returnGoodsNameNode(goodsId, node);
    }
  }, []);

  const goodsPriceRef = useCallback((node) => {
    if (node !== null) {
      returnGoodsPriceNode && returnGoodsPriceNode(goodsId, node);
    }
  }, []);

  const bottomInfoRef = useCallback((node) => {
    if (node !== null) {
      returnBottomInfoNode && returnBottomInfoNode(goodsId, node);
    }
  }, []);
  const tagListRef = useCallback((node) => {
    if (node !== null) {
      returnTagListNode && returnTagListNode(goodsId, node);
    }
  }, []);

  const triggerSkuBlock = () => {
    setSkuBlockStatus(false);
  };

  const showSkuOrAction = (event, buyShowStatus) => {
    if (event) event.stopPropagation();
    setEnablePaymentButton(buyShowStatus);
    setSkuPageShow(true);
    if (!skuPageCompleteStatus) return;
    if (isMultipleSku) {
      setSkuBlockStatus(true);
    }
    if (!isMultipleSku && buyShowStatus) {
      skuSelectRef.current.buyNow();
    }
    if (!isMultipleSku && !buyShowStatus) {
      skuSelectRef.current.addCar();
    }
  };

  useEffect(() => {
    if (skuPageCompleteStatus) {
      showSkuOrAction(false, enablePaymentButton);
    }
  }, [skuPageCompleteStatus]);

  // 是否售罄
  const isSellout = () => {
    return Number(inventory) <= 0;
  };

  const clickClass = goodsId ? 'click-able' : '';

  const clickHandler = (event) => {
    const el = ele.current;
    // 处理zent 的下拉框只能挂载到body下面的问题
    // sku-select-list-body 使用位置 packages/client/pages/components/sku-list/index.js
    const isSelectedContent = document?.getElementsByClassName('sku-select-list-body')?.[0]?.contains(event?.target);
    if ((el && el.contains(event.target)) || isSelectedContent) {
      return setSkuBlockStatus(true);
    }
    setSkuBlockStatus(false);
  };

  const setGoodsMultipleSkuStatus = (hasSkuOptions) => {
    setIsMultipleSku(hasSkuOptions);
  };

  useEffect(() => {
    if (goodsRef.current.offsetWidth && goodsRef.current.offsetWidth < 254) {
      setActionWrapperClass('action-wrapper-overflow');
      setSellOutTagClass('sell-out-tag-min');
      if (setButtonDisplayType && strongProduct) {
        setButtonDisplayType('action-wrapper-overflow');
      }
    } else {
      setActionWrapperClass('action-wrapper');
      setSellOutTagClass('sell-out-tag');
      if (setButtonDisplayType && strongProduct) {
        setButtonDisplayType('action-wrapper');
      }
    }
  }, [grid]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (goodsRef.current.offsetWidth && goodsRef.current.offsetWidth < 254) {
        setActionWrapperClass('action-wrapper-overflow');
        setSellOutTagClass('sell-out-tag-min');
        if (setButtonDisplayType && strongProduct) {
          setButtonDisplayType('action-wrapper-overflow');
        }
      } else {
        setActionWrapperClass('action-wrapper');
        setSellOutTagClass('sell-out-tag');
        if (setButtonDisplayType && strongProduct) {
          setButtonDisplayType('action-wrapper');
        }
      }
    }, 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [clientWidth]);

  useEffect(() => {
    document.addEventListener('click', clickHandler, true);
    return () => {
      document.removeEventListener('click', clickHandler, true);
    };
  }, []);

  const SkuPage = () => {
    const containerDisplay = isPc ? 'block' : 'flex';
    const skuElement = (
      <Style>
        <div
          style={{
            display: skuBlockStatus ? containerDisplay : 'none',
          }}
          ref={ele}
          className='add-shopping-car-container'
          onClick={(e) => e.stopPropagation()}
        >
          <BuyNow
            skuSelectRef={skuSelectRef}
            setGoodsMultipleSkuStatus={setGoodsMultipleSkuStatus}
            goodsId={goodsId}
            kdtId={kdtId}
            triggerSkuBlock={triggerSkuBlock}
            enablePaymentButton={enablePaymentButton}
            setSkuPageCompleteStatus={setSkuPageCompleteStatus}
            currency={currency}
          />
        </div>
      </Style>
    );
    if (isPc) {
      return skuElement;
    }
    return createPortal(skuElement, document.getElementById('root'));
  };
  const [newShowDashDiscountLabel, setNewShowDashDiscountLabel] = useState(showDashDiscountLabel);
  return (
    <Style
      ref={goodsRef}
      className={cn(
        `product-title-align-${productTitleAlign}`,
        className,
        'hover-class',
        `show-dash-discount-label-${newShowDashDiscountLabel}`,
        { 'goods-card-opend': openCard },
      )}
      style={{ backgroundColor: openCard ? cardColor : '' }}
      customProductColor={settings.product_name_color}
      isShowWishOrCart={(shoppingCarConfig?.showShoppingCar && shoppingCarConfig?.style === 'style2') || showWish}
      textPosition={textDisplay === 'superimposed' ? textPosition : 'default'}
      containerHeight={goodsRef?.current?.offsetHeight}
      customPriceColor={settings?.product_price_color}
    >
      <div
        style={{
          '--text_line_limit': textLineLimit,
        }}
        className={cn('feature-collection-grid-item', { 'open-card-class': openCard })}
      >
        <NewLink
          href={`/products/${handle}`}
          onClick={() => {
            buriedPoint('select_item', { ...goodsInfo });
          }}
        >
          <div className={cn('grid-info-wrap', clickClass)}>
            <GoodsImage
              className='grid-info-image'
              goodsInfo={goodsInfo}
              imageRatio={imageRatio}
              hoverShowSecondPic={hoverShowSecondPic}
              imageFillType={imageFillType}
              skuImgUrl={currentSkuImage}
              defaultImageIsSquare={defaultImageIsSquare}
            >
              {isSellout() && (
                <span className={cn('sell-out-tag', sellOutTagClass)}>
                  {intl.formatMessage({ id: 'goods.tips.none_sales' }).toUpperCase()}
                </span>
              )}
            </GoodsImage>
            {textDisplay === 'under_pic' && (
              <>
                <div style={{ width: '100%', position: 'relative' }}>
                  <GoodsColorSku
                    settings={settings}
                    data={goodsInfo}
                    onChange={(item) => {
                      setCurrentSkuImage(item);
                    }}
                  />
                </div>
                {showProductName === true && (
                  <div className='goods-name-container' style={{ minHeight: goodsNameHeight }}>
                    <View
                      ref={goodsNameRef}
                      as='h3'
                      size={productNameSize}
                      className={`goods-name goods-name-${goodsId}`}
                    >
                      {title || "Your product's name"}
                    </View>
                  </div>
                )}
                <div
                  className={cn('goods-price-container', {
                    'show-margin-top': goodsPriceHeight > 0,
                  })}
                  style={{ minHeight: goodsPriceHeight }}
                >
                  {showProductPrice === true && (
                    <PriceStyle
                      ref={goodsPriceRef}
                      className={cn('ht-goods-list-price')}
                      customPriceColor={settings.product_price_color}
                    >
                      <HtGoodsListPrice
                        // ref={goodsPriceRef}
                        size={productPriceSize}
                        bestVariant={bestVariant}
                        currency={currency}
                        variants={variants}
                      />
                    </PriceStyle>
                  )}
                  {showProductPrice === true && (
                    // <div
                    //     className={cn('tag-list-container', { 'show-margin-top': tagListHeight > 0 })}
                    //     style={{ minHeight: tagListHeight }}
                    // >
                    <>
                      {(discountInfo || activityDescriptionInfo?.groupActivityDescription) && (
                        <TagList
                          ref={tagListRef}
                          discountInfo={discountInfo || {}}
                          groupActivityDescription={activityDescriptionInfo?.groupActivityDescription}
                          language={language}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {textDisplay === 'superimposed' && (
              <div className={cn('superimposed-goods-info')}>
                {showProductName === true && (
                  <View as='h3' size={productNameSize} className={`goods-name goods-name-${goodsId}`}>
                    {title || "Your product's name"}
                  </View>
                )}
                {showProductPrice === true && (
                  <HtGoodsListPrice
                    // className='goods-price'
                    size={productPriceSize}
                    bestVariant={bestVariant}
                    currency={currency}
                    variants={variants}
                  />
                )}
                {showProductPrice === true && (
                  <div
                    className={cn('tag-list-container', { 'show-margin-top': tagListHeight > 0 })}
                    style={{ minHeight: tagListHeight }}
                  >
                    {(discountInfo || activityDescriptionInfo?.groupActivityDescription) && (
                      <TagList
                        ref={tagListRef}
                        discountInfo={discountInfo || {}}
                        groupActivityDescription={activityDescriptionInfo?.groupActivityDescription}
                        language={language}
                      />
                    )}
                  </div>
                )}
                {(commentConfig?.showComment || showSale || (showVendor && vendor)) && (
                  <div className='bottom-info'>
                    {!isMobile && (commentConfig?.showComment || showSale) && (
                      <div className='sell-comment'>
                        {commentConfig?.showComment && (
                          <GoodsScore
                            className='sell-goods-score'
                            styleType={commentConfig?.style}
                            score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                          />
                        )}
                        {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                          <div className='comment-num'>
                            {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                            {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                            {intl.formatMessage({ id: 'reviews' })}
                            {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                          </div>
                        ) : null}
                        {showSale && (
                          <div className='sell-num'>
                            {soldNum || 0}&nbsp;
                            {intl.formatMessage({
                              id: '576fd3c7319145ce830919b66e4f78cc',
                              defaultMessage: '销量',
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    {isMobile && (commentConfig?.showComment || showSale) && (
                      <div className='sell-comment'>
                        <div className='comment-container'>
                          {commentConfig?.showComment && (
                            <GoodsScore
                              className='sell-goods-score'
                              styleType={commentConfig?.style}
                              score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                            />
                          )}
                          {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                            <div className='comment-num'>
                              {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                              {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                              {intl.formatMessage({ id: 'reviews' })}
                              {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                            </div>
                          ) : null}
                        </div>
                        {showSale && (
                          <div className='sell-num'>
                            {soldNum || 0}&nbsp;
                            {intl.formatMessage({
                              id: '576fd3c7319145ce830919b66e4f78cc',
                              defaultMessage: '销量',
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    {showVendor && vendor && <span className='vendor-name'>{vendor}</span>}
                  </div>
                )}
              </div>
            )}
            <SimpleGoodsBtn
              className='goods-icon-container'
              showAddCart={shoppingCarConfig?.showShoppingCar && shoppingCarConfig?.style === 'style2'}
              settings={settings}
              data={goodsInfo}
              initData={initialData}
              showWish={showWish}
              onAddCartClick={() => {
                showSkuOrAction(false, false);
              }}
              addCartDisabled={isSellout()}
            />
          </div>
        </NewLink>
      </div>
      {textDisplay === 'superimposed' && (
        <div style={{ width: '100%', position: 'relative' }}>
          <GoodsColorSku
            settings={settings}
            data={goodsInfo}
            onChange={(item) => {
              setCurrentSkuImage(item);
            }}
          />
        </div>
      )}
      <div className={cn('bottom-container', { 'open-card-class': openCard })}>
        {textDisplay === 'under_pic' && (
          <div
            className={cn('bottom-info-container', { 'show-margin-top': bottomInfoHeight > 0 })}
            style={{ minHeight: bottomInfoHeight }}
          >
            {/* 划线价标签 */}
            {!discountInfo && (
              <DashDiscountLabel
                className='dash-discount-label'
                price={bestVariant?.price}
                compareAtPrice={compareAtPrice}
                showDashDiscountLabel={showDashDiscountLabel}
                bordered
                onChangeShowDashDiscountLabel={setNewShowDashDiscountLabel}
              />
            )}

            {(commentConfig?.showComment || showSale || (showVendor && vendor)) && (
              <div ref={bottomInfoRef} className='bottom-info'>
                {!isMobile && (commentConfig?.showComment || showSale) && (
                  <div className='sell-comment'>
                    {commentConfig?.showComment && (
                      <GoodsScore
                        className='sell-goods-score'
                        styleType={commentConfig?.style}
                        score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                      />
                    )}
                    {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                      <div className='comment-num'>
                        {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                        {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                        {intl.formatMessage({ id: 'reviews' })}
                        {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                      </div>
                    ) : null}
                    {showSale && (
                      <div className='sell-num'>
                        {soldNum || 0}&nbsp;
                        {intl.formatMessage({
                          id: '576fd3c7319145ce830919b66e4f78cc',
                          defaultMessage: '销量',
                        })}
                      </div>
                    )}
                  </div>
                )}
                {isMobile && (commentConfig?.showComment || showSale) && (
                  <div className='sell-comment'>
                    <div className='comment-container'>
                      {commentConfig?.showComment && (
                        <GoodsScore
                          className='sell-goods-score'
                          styleType={commentConfig?.style}
                          score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                        />
                      )}
                      {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                        <div className='comment-num'>
                          {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                          {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                          {intl.formatMessage({ id: 'reviews' })}
                          {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                        </div>
                      ) : null}
                    </div>
                    {showSale && (
                      <div className='sell-num'>
                        {soldNum || 0}&nbsp;
                        {intl.formatMessage({
                          id: '576fd3c7319145ce830919b66e4f78cc',
                          defaultMessage: '销量',
                        })}
                      </div>
                    )}
                  </div>
                )}
                {showVendor && vendor && <span className='vendor-name'>{vendor}</span>}
              </div>
            )}
          </div>
        )}
        {((shoppingCarConfig.showShoppingCar && shoppingCarConfig.style === 'style1') || showShoppingBuy) && (
          <div className={cn('bottom-btn-container', buttonDisplayType || actionWrapperClass)}>
            {shoppingCarConfig?.showShoppingCar && shoppingCarConfig?.style === 'style1' && (
              <GoodsButton
                onClick={(e) => {
                  !isSellout() && showSkuOrAction(e, false);
                }}
                type='second'
                size='medium'
                text={intl.formatMessage({ id: 'goods.cart.add' })}
                disabled={isSellout()}
                hover
                active
              />
            )}
            {showShoppingBuy && (
              <GoodsButton
                customClassName={
                  shoppingCarConfig?.showShoppingCar && shoppingCarConfig?.style === 'style1' ? 'btn-buy' : ''
                }
                onClick={(e) => {
                  !isSellout() && showSkuOrAction(e, true);
                }}
                type='primary'
                size='medium'
                disabled={isSellout()}
                hover
                active
              />
            )}
          </div>
        )}
      </div>
      {skuPageShow && (
        <div className='sku-page-container'>
          <SkuPage />
        </div>
      )}
    </Style>
  );
};

export default GoodsPrev;
