import React, { useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { NumberInput } from 'zent';
import get from 'lodash/get';
import { ShareButtons } from 'cpn/share-buttons';
import SkuList from 'cpn/sku-list';
import { DistributionCoupon } from 'cpn/distribution-coupon';
import { GoodsBuyButton } from '../goods-button';
import { DiscountDesc } from 'cpn/discount';
import { HtGoodsDetailPrice, HtGoodsSkuPrice } from 'cpn/goods-price';
import { Style, PriceStyle, GoodsScoreInfoStyle, GoodsInfoText } from './style';
import { GoodsTags } from '../goods-tag';
import View from 'components/base/view';
import { GroupBuyingEntry } from 'components/page/group-buying/group-buying-entry';
// import { GoodsVendor } from 'components/page/goods-detail/goods-vendor';
import { GoodsTitle } from 'components/page/goods-detail/goods-title';
import { GoodsDesc } from 'components/page/goods-detail/goods-desc';
import { ServiceSupport } from 'components/page/goods-detail/service-support';
import { PickupInfo } from 'components/page/goods-detail/pick-up-info';
// import { ReviewsBar } from 'components/page/goods-detail/goods-layout/reviews/review-bar';
import { Wish } from 'components/page/goods-detail/wish';
import GoodsScore from '../../../design/components/goods-score';
import { getReviewCountStrWithZero } from 'utils/formate';
import cn from 'classnames';
import { useGoodsLimit } from 'hook/goods/use-goods-limit';
import { GOODS_FEATURE_TYPE } from 'client/const';
import { useI18n } from 'hook/intl';
import InputNumberStepper from 'components/base/input-number-stepper';
import { getTotalAttachPropsPrice } from 'cpn/sku-list/additional-items/utils';

export const ProductView = (props) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);

  const {
    goodsDetail,
    cartHook,
    // reviewHook,
    wishHook,
    userInfo = {},
    currency,
    language,
    baseConfigInfo,
    themeColor,
    socialShareData,
    history,
    kdtId,
  } = props;

  const productNameSize = baseConfigInfo?.product_name_size;
  const productPriceSize = baseConfigInfo?.product_price_size;
  const showSales = baseConfigInfo?.show_sales;
  const showComment = baseConfigInfo?.show_comment;
  const showVendor = baseConfigInfo?.show_vendor;
  const showStock = baseConfigInfo?.show_stock;
  const showLogisticsDelivery = baseConfigInfo?.show_logistics_delivery;
  const showDashDiscountLabel = baseConfigInfo?.show_dash_discount_label;

  const [visibleDiscountTag, setVisibleDiscountTag] = useState(false); // 有活动的情况下不展示折扣标签

  const { customerId } = userInfo;
  const {
    isInitNeedCheck,
    addCart,
    buySingleGoods,
    cart,
    onNumChange,
    onSkuSelect,
    onGoodsAdditionalItemSelect,
  } = cartHook;
  // const { showReview, reviewOverview, reviewTotalCount } = reviewHook;

  const { wishActionLoading, wishStatus, toggleToWishList } = wishHook;

  const {
    title,
    variants = [],
    // discountInfo,
    withVariants,
    options,
    // bestVariant = {},
    goodsReviewsInfo = {},
    vendor,
    soldNum,
    quantity,
    goodsDesc = '',
    discountInfos = [],
    limitInfo,
    attachProps = [],
  } = goodsDetail;
  const { reviewsCount = 0, averageScore = 5 } = goodsReviewsInfo;
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);

  const discountInfo = useMemo(() => {
    let res = discountInfos[0] || {};

    let activityId = '';
    if (cart?.variantId && variants.length > 0) {
      variants.forEach((item) => {
        if (item.variantId === cart?.variantId) {
          activityId = item?.discountInfo?.activityId;
        }
      });
    }
    if (activityId && discountInfos.length > 0) {
      discountInfos.forEach((item) => {
        if (item.activityId === activityId) {
          res = item;
        }
      });
    }
    return res;
  }, [cart?.variantId]);

  // 起购, 限购提醒
  const { limitTips, minGoodsNum, maxGoodsNum, onMinusEvent, onPlusEvent } = useGoodsLimit({
    intl,
    inventory: cart?.variantId ? cart?.inventory : goodsDetail?.quantity,
    minPurchaseQty: goodsDetail?.minPurchaseQty,
    limitInfo,
  });

  const attachPropsPrice = getTotalAttachPropsPrice(cart.goodsAdditionalItems);
  /** 获取指定规格 显示指定规格 */
  // size={productPriceSize} 静怡
  return (
    <Style>
      <GoodsTitle title={title} productNameSize={productNameSize} />
      <GoodsDesc desc={goodsDesc} />
      {showLogisticsDelivery && (
        <GoodsTags goodsFeatureType={goodsDetail?.goodsFeatureType} goodsId={goodsDetail?.goodsId} />
      )}
      {(showComment || (showVendor && !!vendor) || showSales || showStock) && (
        <GoodsScoreInfoStyle>
          {showComment && (
            <>
              <GoodsScore score={averageScore} scoreSize={16} className='goods-score-container' />
              <GoodsInfoText>
                {getReviewCountStrWithZero(reviewsCount)}&nbsp;
                {intl.formatMessage({ id: 'reviews', defaultMessage: '评价' })}
              </GoodsInfoText>
            </>
          )}
          {!!vendor && showVendor && (
            <GoodsInfoText>
              <GoodsInfoText className='goods-vendor'>{vendor}</GoodsInfoText>
            </GoodsInfoText>
          )}
          {showSales && (
            <GoodsInfoText>
              {getReviewCountStrWithZero(soldNum) || 0}&nbsp;
              {intl.formatMessage({
                id: '576fd3c7319145ce830919b66e4f78cc',
                defaultMessage: '销量',
              })}
            </GoodsInfoText>
          )}
          {showStock && (
            <GoodsInfoText>
              {getReviewCountStrWithZero(cart?.inventory || quantity || 0)}&nbsp;
              {intl.formatMessage({
                id: '6bc746c24a104015a0c48f148f00f607',
                defaultMessage: '可售',
              })}
            </GoodsInfoText>
          )}
        </GoodsScoreInfoStyle>
      )}
      <div className='price-wish-wrapper'>
        <PriceStyle className='goods-price'>
          <View className='goods-price-size'>
            <HtGoodsDetailPrice
              goodsDetail={goodsDetail}
              size={productPriceSize}
              showDashDiscountLabel={showDashDiscountLabel}
              variantId={cart?.variantId}
              quantity={cart?.quantity}
              setVisibleDiscountTag={setVisibleDiscountTag}
              attachPropsPrice={attachPropsPrice}
            />
            {/* {!cart?.variantId ? (
                            <HtGoodsDetailPrice
                                goodsDetail={goodsDetail}
                                size={productPriceSize}
                                showDashDiscountLabel={showDashDiscountLabel}
                            />
                        ) : (
                            <HtGoodsSkuPrice
                                size={productPriceSize}
                                price={cart?.price}
                                bestPriceInfo={cart?.bestPriceInfo}
                                compareAtPrice={cart?.compareAtPrice}
                                discountInfo={cart?.discountInfo}
                                memberInfo={cart?.memberInfo}
                                variantId={cart?.variantId}
                                showDashDiscountLabel={showDashDiscountLabel}
                            />
                        )} */}
          </View>
        </PriceStyle>
        {![GOODS_FEATURE_TYPE.newService, GOODS_FEATURE_TYPE.eCard].includes(goodsDetail?.goodsFeatureType) && (
          <Wish
            themeColor={themeColor}
            wishStatus={wishStatus}
            wishActionLoading={wishActionLoading}
            toggleToWishList={toggleToWishList}
          />
        )}
      </div>
      <DistributionCoupon goodsId={goodsDetail?.goodsId} customerId={customerId} currency={currency} />
      {/* <ReviewsBar
                reviewOverview={reviewOverview}
                reviewTotalCount={reviewTotalCount}
                soldNum={goodsDetail?.soldNum}
                showSales={false} // 显示销量的配置项整合到上面去了,所以这里写死不展示销量
                // // showReview={showReview}
            /> */}
      {!!discountInfo?.discountType && (
        <DiscountDesc
          showLink
          // showTag={visibleDiscountTag && cart.variantId}
          showTag={false}
          className='detail-desc'
          discountInfo={discountInfo}
          title={intl.formatMessage({
            description: '促销活动',
            id: 'ff5ba49df86548e5b17ec1d4e543ca81',
          })}
          currency={currency}
          language={language}
          onLink={() => {
            if (window.isEdit) return;
            window.open(
              `/discount/${discountInfo?.discountType ?? 'fix'}/goods?activityId=${discountInfo?.activityId}`,
            );
          }}
        />
      )}
      {/* 拼团引导入口 */}
      <GroupBuyingEntry goodsId={goodsDetail?.goodsId} currency={currency} goodsDetail={goodsDetail} />
      {/* 商品详情的多规格展示 */}
      <SkuList
        cart={cart}
        options={options}
        variants={variants}
        onSkuSelect={onSkuSelect}
        withVariants={withVariants}
        isNeedDisabled={isNeedDisabled}
        attachProps={attachProps}
        onGoodsAdditionalItemSelect={onGoodsAdditionalItemSelect}
        isInitNeedCheck={isInitNeedCheck}
      />
      <div className='sku-wrapper'>
        <span className='sku-title'>
          <FormattedMessage id='common_count' />
        </span>
        <div className='goods-sku-detail'>
          <InputNumberStepper
            value={cart.quantity}
            min={minGoodsNum}
            max={maxGoodsNum}
            onMinusEvent={onMinusEvent}
            onPlusEvent={onPlusEvent}
            disabled={isNeedDisabled}
            onChange={onNumChange}
          />
          {limitTips && <div className='limit-tips'>{limitTips}</div>}
        </div>
      </div>
      <GoodsBuyButton
        goodsDetail={goodsDetail}
        onAddCart={addCart}
        onBuyOnce={buySingleGoods}
        kdtId={kdtId}
        currency={currency}
        setIsNeedDisabled={setIsNeedDisabled}
        cart={cart}
      />
      <ShareButtons
        goodsDetail={goodsDetail}
        customClass='goods-detail-share-btns'
        title={get(goodsDetail, 'title', '')}
        handle={get(goodsDetail, 'handle', '')}
        socialShareData={socialShareData}
      />
      <div className='space'>
        <PickupInfo goodsId={goodsDetail?.goodsId} />
      </div>
      <div className='space'>
        <ServiceSupport />
      </div>
      {/* 插槽 */}
      {props.children}
    </Style>
  );
};
