/* eslint-disable prettier/prettier */
// 订单状态枚举
export enum ORDER_STATUS {
  WAIT_PAYMENT = 'wait_payment', // "待付款"
  UNCONFIRMED = 'unconfirmed', //  "待确认"
  PAYING = 'paying', // "支付中"
  WAITING = 'waiting', // "等待中" // 已付款暂不发货的三方支付
  WAIT_FULLFILLMENBT = 'wait_fulfillment', // "待发货, 已经生成履约单"
  CANCELED = 'canceled', // "已取消"
  WAIT_RECEIVED = 'wait_received', // "待收款"
  FULFILLED = 'fulfilled', // "已发货"
  PART_FULFILLED = 'partial_fulfillment', // "已部分发货"
  REFUNDING = 'refunding', // "退款中"
  REFUNDED = 'refunded', // "已退款"
  REFUND_FAILURE = 'refund_failure', // "退款失败"
  PAID = 'paid', // 已支付
  COMPLETED = 'completed', // 已完成
}
// 订单状态配置
export const OrderStatusConfig = {
  [ORDER_STATUS.WAIT_PAYMENT]: {
    id: ORDER_STATUS.WAIT_PAYMENT,
    name: '待付款',
    key: 'order.pending', // 国际化id
    // tagMap: RateOptions.EMPTY, // 状态对应的tag样式map
  }, // "待付款"
  [ORDER_STATUS.UNCONFIRMED]: {
    id: ORDER_STATUS.UNCONFIRMED,
    name: '待确认',
    key: 'order_unconfirmed',
    // tagMap: RateOptions.EMPTY,
  }, //  "待确认"
  [ORDER_STATUS.PAYING]: {
    id: ORDER_STATUS.PAYING,
    name: '支付中',
    key: 'pay_pending',
    // tagMap: RateOptions.EMPTY,
  }, // "支付中"
  [ORDER_STATUS.WAITING]: {
    id: ORDER_STATUS.WAITING,
    name: '等待中',
    key: '97874a76947047ad8c45ed5577aa8f07',
    // tagMap: RateOptions.EMPTY,
  }, // "等待中；已付款暂不发货的三方支付"
  [ORDER_STATUS.WAIT_FULLFILLMENBT]: {
    id: ORDER_STATUS.WAIT_FULLFILLMENBT,
    name: '待发货',
    key: 'order.waitSend',
    // tagMap: RateOptions.EMPTY_ORANGE,
  }, // "待发货, 已经生成履约单"
  [ORDER_STATUS.CANCELED]: {
    id: ORDER_STATUS.CANCELED,
    name: '已取消',
    key: '8537855511464194ac8a2751f4ac55d4',
    // tagMap: RateOptions.FULL,
  }, // "已取消"
  [ORDER_STATUS.WAIT_RECEIVED]: {
    id: ORDER_STATUS.WAIT_RECEIVED,
    name: '待付款',
    key: 'order.pending',
    // tagMap: RateOptions.EMPTY_GREEN,
  }, // "待收款"
  [ORDER_STATUS.FULFILLED]: {
    id: ORDER_STATUS.FULFILLED,
    name: '已发货',
    key: 'order.detail.has_send',
    // tagMap: RateOptions.FULL,
  }, // "已发货"
  [ORDER_STATUS.REFUNDING]: {
    id: ORDER_STATUS.REFUNDING,
    name: '退款中',
    key: '01a2f5580d834f05b17c005caf081005',
    // tagMap: RateOptions.EMPTY,
  }, // "退款中"
  [ORDER_STATUS.REFUNDED]: {
    id: ORDER_STATUS.REFUNDED,
    name: '已退款',
    key: 'order.refunded',
    // tagMap: RateOptions.FULL,
  }, // "已退款"
  [ORDER_STATUS.REFUND_FAILURE]: {
    id: ORDER_STATUS.REFUND_FAILURE,
    name: '退款失败',
    key: 'f1d66b5c48154d42868efb8855616d62',
    // tagMap: RateOptions.FULL_RED,
  }, // "退款失败"
  [ORDER_STATUS.COMPLETED]: {
    id: ORDER_STATUS.COMPLETED,
    name: '已完成',
    key: '279d0d7123ae4163828c0a5ec9a33ab3',
  }, // "已完成"
  [ORDER_STATUS.PART_FULFILLED]: {
    id: ORDER_STATUS.PART_FULFILLED,
    name: '已部分发货',
    key: 'PartialDelivery',
  },
};

// 支付状态枚举
export enum ORDER_PAYMENT_STATUS {
  PENDING = 'pending', // 待付款
  // 新增支付中（原已授权包装到此状态）
  PAYING = 'paying', // 支付中
  UNCONFIRMED = 'unconfirmed', // 待确认
  PAID = 'paid', // 已付款
  CANCELED = 'canceled', // 已取消 新增
  REFUNDING = 'refunding', // 退款中 新增
  REFUNDED = 'refunded', // 原为已退款，现为退款成功
  REFUND_FAILURE = 'refund_failure', // 退款失败 新增
  PARTIALLY_REFUNDED = 'partially_refunded', // 原为已部分退款，现为部分退款成功

  AUTHORIZED = 'authorized', // 原授权，现被包装到了支付中，所以将不会被使用
  PARTIALLY_PAID = 'partially_paid', // 部分付款。现有需求暂未使用
  VOIDED = 'voided', // 已撤销，暂未使用上

  // 这里也搞不明白后端为啥查询状态和数据状态不一致
  ANY = 'any', // 任何，查询状态
  UNPAID = 'unpaid', //  未支付,查询状态
}

// 支付状态多语言map
export const orderPaymentStatusMap = {
  // 新增
  [ORDER_PAYMENT_STATUS.PAYING]: 'pay_pending',
  [ORDER_PAYMENT_STATUS.CANCELED]: '8537855511464194ac8a2751f4ac55d4',
  [ORDER_PAYMENT_STATUS.REFUNDING]: '01a2f5580d834f05b17c005caf081005',
  [ORDER_PAYMENT_STATUS.REFUND_FAILURE]: 'f1d66b5c48154d42868efb8855616d62',

  [ORDER_PAYMENT_STATUS.PARTIALLY_PAID]: 'order.partially_paid',
  [ORDER_PAYMENT_STATUS.PARTIALLY_REFUNDED]: '30fe8e8ac66f4dceabd43f7e630e6303',
  [ORDER_PAYMENT_STATUS.REFUNDED]: 'b880357324894ea1934bfa93f471b108',
  [ORDER_PAYMENT_STATUS.PAID]: 'order.paid',
  [ORDER_PAYMENT_STATUS.UNPAID]: 'order.pending',
  [ORDER_PAYMENT_STATUS.PENDING]: 'order.pending',
  [ORDER_PAYMENT_STATUS.AUTHORIZED]: 'pay_pending',
  [ORDER_PAYMENT_STATUS.VOIDED]: 'order.voided',
  [ORDER_PAYMENT_STATUS.UNCONFIRMED]: 'order_unconfirmed',
};

export const DELIVERY_STATUS = {
  FULFILLED: 'fulfilled', // 已发货
  NULL: 'none', // 未发货
  PARTIALLY: 'partial', // 部分发货
  NOT_REQUIRES: 'not_requires', // 无需发货
  HOLD: 'hold', // 暂时不发货 -比如待拼团状态
};

export const DeliveryStatusMap = {
  [DELIVERY_STATUS.FULFILLED]: 'order.detail.has_send',
  [DELIVERY_STATUS.NULL]: 'order.detail.un_send',
  [DELIVERY_STATUS.HOLD]: 'order.detail.un_send',
  [DELIVERY_STATUS.PARTIALLY]: 'order.detail.partial',
  [DELIVERY_STATUS.NOT_REQUIRES]: '4d84e69cef40454d82c5baf46ceb2c49',
};

export const SUPPORT_LANGUAGE = {
  zhCN: 'zh-CN',
  zhTM: 'zh-TW',
  JA: 'ja',
  EN: 'en',
};

export const LOGIN_CHANNELS = {
  facebook: 'facebook',
  google: 'google',
  line: 'line',
  thirdApp: 'third-app',
};

export const payMethods = {
  PAYPAL: 1,
  STRIPE: 2,
  ALLINPAY: 3,
  CLOVER: 22,
};

// 分享链接中折扣码的cookie名称
export const DISCOUNT_CODE = 'discount_code';

/** 页面打开模式 */
export const OPEN_MODE = {
  PREVIEW_SIMPLE: 1,
  PREVIEW_NORMAL: 2,
  EDIT_NORMAL: 3,
};

export const RESERVE_ENTRY_ENUM = {
  GOODS_DETAIL: 10,
  ORDER_DETAIL: 20,
  EMAIL: 30,
};

export const CAPTCHA_SITE_KEY = '6LdEez8aAAAAAKTYLMZJkrmg5EN3P1AKPbzYPP3m';

// sessionStorage 中使用
export const IS_OPEN_LOGIN = 'is_open_login';
export const SHOP_LOGO_URL = 'shop_logo_url';

// 店铺状态
export const STORE_STATUS = {
  VALID: 'valid',
  CLOSING: 'closing',
  PROTECTION: 'protection',
};

// 是否开启主域名跳转
export const MAIN_DOMAIN_REDIRECT_STATUS = {
  OPEN: 'open',
};

export const POP_THEME_TYPE = {
  EMAIL_SUBSCRIBE: 'email_subscribe', // 邮件订阅
  DISCOUNT_ACTIVITY: 'discount_activity', // 折扣活动
  ACTIVITY_NOTE: '', // 活动通知 这个类型的弹窗还没开发，先不用关注
  WARM_NOTE: 'warm_note', // 政策
  PIC_ADV: 'pic_adv', // 图片广告
};
export const MessageType = {
  CLIENT_CHANGE: 'clientChange',
  ADMIN_CHANGE: 'adminChange',
  CURRENT_SECTION: 'currentSection',
  HASH_CHANGE: 'hashChange',
  SECTIONSELECT: 'cToB::sectionSelect',
  EDIT_INFO: 'edit_info',
  SECTION_MOVE_UP: 'section_move_up',
  SECTION_MOVE_DOWN: 'section_move_down',
  SECTION_DELETE: 'section_delete',
  SECTION_COPY: 'section_copy',
  SITE_SWITCH: 'site_switch',
  TEMPLATE_SAVE: 'template_save',
  DISABLEJS: 'disable_js',
};

export const GOODS_STATUS_SELLING = {
  DOWN: 2,
};

export const DEFAULT_IMAGE_SRC = 'https://img01.yzcdn.cn/upload_files/2021/08/16/FmjmzgML0mx3cCH8SvTQ2xvcopVV.png';

export const COD_METHOD_NAME = 'cod';

export const TRACK = {
  AddCartButton: 'track-add-cart-button',
  BuyOnceButton: 'track-buy-once-button',
};

export const FIXED_SECTIONS = [
  'announcement-bar',
  'header',
  'footer',
  'member-info',
  'member-discount',
  'member-reward',
  'member-goods',
  'member-slide',
];

export const COLLECTION_PAGE_SECTIOCNS = ['collection-cover-pic', 'collection-template'];

/** 装修数据类型 */
export const ThemeDataType = {
  MemberThemeData: 'memberThemeData',
  ThemeData: 'themeData',
};

export const HEAD_ICON_TYPE = {
  cart: 'head-icon-cart',
  wish: 'head-icon-wish',
};

export const CALLBACK_TYPE = {
  line: 'line',
};

export const SWITCH_OPEN_STATUS = {
  OPEN: 'open',
  CLOSE: 'close',
};

export const SWITCH_OPEN_STATUS_ON_OFF = {
  on: 'on',
};

export const UTM_KEYS_MAP = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const DELIVERY_METHOD = {
  local_delivery: 'local_delivery',
  shipping_method: 'shipping_method',
  pickup: 'pickup',
};

export enum GOODS_FEATURE_TYPE {
  physical = 'physical', // 实物商品
  virtual = 'virtual',  // 虚拟商品
  newService = 'new_service', // 服务商品
  eCard = 'e_card',// 卡券商品
}